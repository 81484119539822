<template>
  <div class='JZContainer'>
    <content-block title="商品管理">
      <query-form  size="small" ref='JZForm'  label-width="120px" @search="handleSearch" @reset='handleReset'>
        <el-form-item label="商品编码：">
            <el-input v-model.trim="JZForm.goodsCode"  placeholder="请输入商品编码"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：">
            <el-input v-model.trim="JZForm.name"  placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="规格：">
            <el-input v-model.trim="JZForm.specification"  placeholder="请输入规格"></el-input>
        </el-form-item>
        <el-form-item label="型号：">
            <el-input v-model.trim="JZForm.model"  placeholder="请输入型号"></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
            <el-input v-model.trim="JZForm.brandName"  placeholder="请输入品牌"></el-input>
        </el-form-item>
        <el-form-item label="生产商：">
            <el-input v-model.trim="JZForm.enterpriseName"  placeholder="请输入生产商"></el-input>
        </el-form-item>
        <el-form-item label="厂家编码：">
            <el-input v-model.trim="JZForm.factoryCode"  placeholder="请输入厂家编码"></el-input>
        </el-form-item>
        <el-form-item label="是否医疗件：">
            <el-select v-model.trim="JZForm.medicalFlag" @change="handleSearch">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="医疗件" :value="true"></el-option>
              <el-option label="非医疗件" :value="false"></el-option>
            </el-select>
        </el-form-item>
      </query-form>
    </content-block>
    <content-block title="已上架商品">
      <TableWithPagination
        :tableData="targetLists"
        ref="productTable"
        :columnList="columnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
        :leftToolbar="tableButtons"
        :checkbox-config="{reserve:true}"
        @selection-change="handleSelectionChange"
      ></TableWithPagination>
    </content-block>
    <BatchPriceAdjustment @handleSearch='resetHandleSearch' ref="batchPriceAdjustment"></BatchPriceAdjustment>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import {getSupplierGoodsPageList, exportSupplierGoodsPageList} from '@/api/userCenter';
import BatchPriceAdjustment from './components/batchPriceAdjustment';
export default {
  name: 'Product',
  mixins: [InfoMixin],
  components: {
    BatchPriceAdjustment,
  },
  computed: {
    tableButtons () {
      return [
        {
          name: '调库存', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'inventoryAdjustment', // code(必填)
          disabled: !this.selectedLists.length,
          icon: 'el-icon-plus', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.inventoryAdjustment, // 点击回调事件
        },
        {
          name: '导出', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'exportTable', // code(必填)
          disabled: !this.pagination.total,
          icon: '', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.exportTable, // 点击回调事件
        },
      ];
    },
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
  },
  methods: {
    resetHandleSearch () {
      this.selectedLists = [];
      this.$refs.productTable.clearCheckboxRow();
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        ...this.JZForm,
        status: 'UP',
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      getSupplierGoodsPageList(currentParams).then((res) => {
        this.targetLists = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    inventoryAdjustment () {
      let idLists = this.selectedLists.map(item=>item.skuId);
      this.$refs.batchPriceAdjustment.idLists = idLists;
      this.$refs.batchPriceAdjustment.initPage();
    },
    exportTable () {
      let currentParams = {
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize > 100 ? 100 : this.pagination.pageSize,
        ...this.JZForm,
        status: 'UP',
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      exportSupplierGoodsPageList(currentParams).then(({data})=>{
        const downloadData = {
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `上架商品_${Date.now()}`,
        };
        this.downBinaryStreamHandle(downloadData);
      });
    },
    handleSelectionChange (list) {
      this.selectedLists = list || [];
    },
    handleReset () {
      this.JZForm = {
        goodsCode: '',
        name: '',
        specification: '',
        model: '',
        brandName: '',
        customerName: '',
        medicalFlag: null,
        enterpriseName: '',
        factoryCode: '',
      };
      this.handleSearch();
    },
    downBinaryStreamHandle ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  data () {
    return {
      JZForm: {
        goodsCode: '',
        name: '',
        specification: '',
        model: '',
        brandName: '',
        customerName: '',
        medicalFlag: null,
        enterpriseName: '',
        factoryCode: '',
      },
      selectedLists: [],
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '全部',
          align: 'center',
          minWidth: '80px',
          fixed: 'left',
          type: 'checkbox',
          prop: 'id',
        },
        {
          tooltip: true,
          label: '商品编码（sku）',
          minWidth: '150px',
          prop: 'goodsCode',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '规格',
          minWidth: '110px',
          prop: 'specification',
        },
        {
          tooltip: true,
          label: '型号',
          minWidth: '110px',
          prop: 'model',
        },
        {
          tooltip: true,
          label: '所属品牌',
          minWidth: '150px',
          prop: 'brandName',
        },
        {
          tooltip: true,
          label: '计量单位',
          minWidth: '100px',
          prop: 'unitName',
        },
        {
          tooltip: true,
          label: '销售单价',
          minWidth: '120px',
          prop: 'purchasePrice',
        },
        {
          tooltip: true,
          label: '库存数量',
          minWidth: '120px',
          prop: 'stock',
        },
        {
          tooltip: true,
          label: '生产商',
          minWidth: '180px',
          prop: 'enterpriseName',
        },
        {
          tooltip: true,
          label: '厂家编码',
          minWidth: '120px',
          prop: 'factoryCode',
        },
        {
          tooltip: true,
          label: '保修期',
          minWidth: '120px',
          prop: 'period',
          render: (h, {row}) => {
            let period = row.period ? `${row.period}${row.periodUnit}` : '--';
            return h('span', period);
          },
        },
        {
          tooltip: true,
          label: '是否医疗件',
          minWidth: '110px',
          prop: 'medicalFlag',
          format: (val) => {
            return val === true ? '是' : val === false ? '否' : '--';
          },
        },
        {
          tooltip: true,
          label: '产品注册证/备案凭证',
          minWidth: '180px',
          prop: 'registerCode',
        },
        {
          tooltip: true,
          label: '上架状态',
          minWidth: '120px',
          prop: 'status',
          format: (val) => {
            return val === 'UP' ? '上架' : val === 'DOWN' ? '下架' : '--';
          },
        },
        {
          tooltip: true,
          label: '上架渠道',
          minWidth: '130px',
          prop: 'expiryTime',
          format: () => {
            return '小紫供应链';
          },
        },
        {
          tooltip: true,
          label: '更新时间',
          minWidth: '150px',
          prop: 'lastUpdateTime',
          format: this.$options.filters['date-format'],
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
