<template>
  <el-dialog
    title="批量调库存"
    :visible.sync="isShow"
    width="600px"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'
  >
    <el-form>
      <el-row style="padding-left:30px">
        <el-radio-group v-model="adjustType">
          <el-col :span="24">
            <el-radio :label="1">
              库存
              <el-select v-model="method" size="mini" placeholder="请选择" style="width:115px;margin-left:5px;">
                <el-option label="上调" value="ADD"></el-option>
                <el-option label="下调" value="SUB"></el-option>
              </el-select>
              <el-input-number v-model.trim="stock1" :precision="0" :step="1" :min="0" placeholder="请输入调整库存" size="mini"  style="width:195px;margin-left:5px;" ></el-input-number>
              <!-- <el-input placeholder="请输入调整库存" size="mini" type="number" style="width:200px" v-model.trim="stock1"></el-input> -->
              PE
            </el-radio>
          </el-col>
          <el-col :span="24" style="margin-top:10px;">
            <el-radio :label="2">调至
              <el-input-number v-model.trim="stock2" :precision="0" :step="1" :min="0" placeholder="请输入调整库存" size="mini"  style="width:320px;margin-left:5px;" ></el-input-number>
              PE
            </el-radio>
          </el-col>
        </el-radio-group>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
      <el-button @click="isShow=false" size="mini">取 消</el-button>
      <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {adjustStock} from '@/api/userCenter';
export default {
  name: 'BatchPriceAdjustment',
  methods: {
    initPage () {
      this.isShow = true;
      this.adjustType = '';
      this.stock1 = '';
      this.stock2 = '';
    },
    handleEnsure () {
      if (!this.adjustType) {
        this.$JZShowAlert('请选择调整方式', 'error');
        return;
      }
      if (this.adjustType === 1 && !this.stock1) {
        this.$JZShowAlert('请输入调整库存', 'error');
        return;
      }
      if (this.adjustType === 2 && !this.stock2) {
        this.$JZShowAlert('请输入调整库存', 'error');
        return;
      }
      let currentParams = {
        adjustType: this.adjustType === 2 ? 'SET' : this.adjustType === 1 ? this.method : '',
        skuIdList: this.idLists,
        stock: this.adjustType === 2 ? this.stock2 : this.adjustType === 1 ? this.stock1 : '',
      };
      adjustStock(currentParams).then(() => {
        this.$emit('handleSearch');
        this.isShow = false;
      });
    },
  },
  data () {
    return {
      method: 'ADD',
      adjustType: '',
      stock: '',
      idLists: '',
      isShow: false,
      stock1: '',
      stock2: '',
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
